// extracted by mini-css-extract-plugin
export var ArtistDescription = "KoolKoor-module--ArtistDescription--NGNx5";
export var ArtistInfos = "KoolKoor-module--ArtistInfos--+lD+T";
export var ButtonWrapper = "KoolKoor-module--ButtonWrapper --Rqq8L";
export var CardWrapper = "KoolKoor-module--CardWrapper--tHe5r";
export var CarrouselWrapper2 = "KoolKoor-module--CarrouselWrapper2--a78YN";
export var Citations = "KoolKoor-module--Citations--kKncG";
export var DescriptionWrapper = "KoolKoor-module--DescriptionWrapper--YWjdH";
export var ImageWrapper = "KoolKoor-module--ImageWrapper--ZZYV7";
export var LinkWrapper = "KoolKoor-module--LinkWrapper--Jip0a";
export var MobileProtrait = "KoolKoor-module--MobileProtrait--3p1d5";
export var More = "KoolKoor-module--More--kfDFB";
export var MoreButton = "KoolKoor-module--MoreButton--gFiZQ";
export var NameWrapper = "KoolKoor-module--NameWrapper--1BHWV";
export var PdpWrapper = "KoolKoor-module--PdpWrapper--F1IQz";
export var PhotosWrapper = "KoolKoor-module--PhotosWrapper--NVDWy";
export var ProfilWrapper = "KoolKoor-module--ProfilWrapper--HKnhE";
export var TitleWrapper = "KoolKoor-module--TitleWrapper--4S8rL";
export var Wrapper = "KoolKoor-module--Wrapper--ugnJ3";