import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./KoolKoor.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import { ListWrapper } from "../../Expositions/Expositions.module.css";
import EventCard from "../../../../components/EventCard";
import KoolKoorImg1 from "../../../../res/KoolKoor/KoolKoor.png"
import KoolKoorImg2 from "../../../../res/Photos site/Kool koor/A-One _ Kool Koor_Milano 1985.jpg"
import KoolKoorImg3 from "../../../../res/Photos site/Kool koor/Affiche Date curated - Crash _ Daze _ Kool Koor _ Sharp_ Toxic _ Wong _ Lee _ Lady.png"
import KoolKoorImg4 from "../../../../res/Photos site/Kool koor/Kool koor studio .jpg"
import KoolKoorImg5 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Kool Koor, Babel On, 2021.jpg"
import KoolKoorImg6 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Kool koor - Inside Out - 2021.png"
import KoolKoorImg7 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Kool koor - Untitled - 1986.jpeg"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/KoolKoor/portrait.jpg'
import { Helmet } from "react-helmet";

import PastPresent1 from "../../../../res/KoolKoor/past-present-1.jpg"
import PastPresent2 from "../../../../res/KoolKoor/past-present-2.jpg"
import PastPresent3 from "../../../../res/KoolKoor/past-present-3.jpg"

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "kool-koor",
  name: "Kool Koor",
  description: 
  "Charles William Hargrove Jr. known as Kool Koor, born in New York in 1963, as a true child of the Bronx, quickly developed a love for Graffiti. After a childhood of passion for drawing, Kool Koor started expressing himself on walls in 1976. At only 16 he found himself exhibited at Fashion Moda gallery, having developed a style made of futuristic landscapes, neither figurative or abstract, sprayed onto canvas with powerful and vibrant tones. He slowly broke through the East Village into the Soho art scene before moving to Europe, becoming one of the first artists of the Brussels graffiti scene. Kool Koor’s work interprets in its recurring composition present, past and future through intergalactic frescos. His iconography is populated with spaceships and energetic tags, following the outlines of Gothic Futurism initiated by his friend and mentor Rammellzee. The artist was welcomed for a residence at the Château de Forbin during the summer of 2021. His work was largely exhibited, notably at the Metropolitan Museum in New York, at the Chicago Renaissance Society at the Butler Museum as well as the Groninger Museum in the Netherlands.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Kool Koor.",
  photos: [
    { src: PastPresent1, name: "Kool Koor" },
    { src: PastPresent2, name: "Kool Koor" },
    { src: PastPresent3, name: "Kool Koor" },
  ],
  citations: [
    "« Mon premier nom d'artiste était The Arbitrator Koor. Je me suis appelé The Arbitrator Koor parce que j'avais l'impression d'être un arbitre dans mon travail artistique; L’arbitre entre une réalité que nous connaissons et une réalité qui est autre. Kool est apparu plus tard, lorsque j'ai commencé à enregistrer de la musique. Quand j'étais jeune adulte, j'étais attiré par le jeu d’échecs - I like the tower or the rook - Je voulais utiliser \"Rook\" comme nom d’artiste mais je l'ai abandonnée. J'ai pris le K et le R et je les ai retournés. C'est comme ça que j'ai eu mon nom d'artiste. Il reflète ma façon de penser et personnifie ma créativité : jeu de la ligne, architecture et mondes imaginaires, Si vous regardez la vie ou les choses de l'autre côté, vous pouvez souvent les comprendre. C'est ainsi qu'est né Koor »"
  ]
};

const exhibitions = [
  {
    date: "Juillet Aout 2021",
    title: "Rooks & Robots",
    link: "kool-koor",
    artist: "Kool Koor",
    place: "2 rue de Belloi - 13006 Marseille",
    description: "A la fois rétrospective de 40 ans de création artistique (1977-2017) et présentation des oeuvres qu’il a produites en avril et mai derniers lors de sa résidence artistique au Château de Forbin, cette double exposition est une première dans la carrière de l’artiste. Ce parcours initiatique à travers les différents supports et différentes techniques (aérosol, marqueur et acrylique) utilisés par Kool Koor permet au spectateur de suivre et de saisir l’évolution de son travail sur le thème des Rooks & Robots.", 
    presentation_photo: KoolKoorImg1,
  },
]

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: KoolKoorImg5, name: "Kool Koor", artist:"Kool Koor\n(Charles Hargrove dit) (né en 1963)", title:"Babel On, 2021", desc1: "Peinture aérosol et marqueur sur toile", desc2 :"Spray paint and marker on canvas", desc3: "160 x 90 cm", desc4:" 63 x 35 1/2 in.", desc7: "- Réalisée lors de la résidence d'artiste au Château de Forbin, France.", desc8:"<h4>-<i> Nos Fantômes</i> - exposition inaugurale, Ghost galerie, Paris, France.\n10 Septembre - 20 Novembre, 2021.\n- <i>Rooks & Robots</i>, Château de Forbin, Marseille, France.\n4 Juillet - 4 Octobre, 2021</h4><", desc9:"<h4> <i>Rooks & Robots</i>, Château de Forbin, Marseille, France. 4 Juillet - 4 Octobre, 2021, reproduit en pleine page couleur p.34.</h4>" },
    { src: KoolKoorImg6, name: "Kool Koor", artist:"Kool Koor\n(Charles Hargrove dit) (né en 1963)", title:"INSIDE - OUT, 2021", desc1: "Aérosol et marqueur sur toile", desc2:"Spray paint and marker on canvas", desc3:"80 x 130 cm", desc4: "31 1/2 x 51 1/8 in.", desc5:"Signée, titrée et datée au dos", desc6:"Signed, titled and dated on the reverse", desc7:"- Réalisée lors de la résidence d'artiste au Château de Forbin, France.", desc8:"<h4>-<i> Nos Fantômes</i> - exposition inaugurale, Ghost galerie, Paris, France.\n10 Septembre - 20 Novembre, 2021.\n- <i>Rooks & Robots</i>, Château de Forbin, Marseille, France.\n4 Juillet - 4 Octobre, 2021</h4><", desc9:"<h4> <i>Rooks & Robots</i>, Château de Forbin, Marseille, France. 4 Juillet - 4 Octobre, 2021, reproduit en pleine page couleur p.34.</h4>"},
    { src: KoolKoorImg7, name: "Kool Koor", artist:"Kool Koor\n(Charles Hargrove dit) (né en 1963)", title:"Untitled, 1986", desc1:"Aérosol sur toile", desc2:"Spray paint on canvas", desc3:"180,5 x 250,5 cm", desc4:"73 x 98 1/2 in.", desc7:"- Collection particulière."},
  ],
};

const DondiWhite = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>KOOL KOOR <h2 style={{paddingLeft: "16px"}}>(Charles Hargrove - American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1963</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <a href={"/en/expositions/" + exhibitions[0].link} className={LinkWrapper}>« Rooks & Robots » - du 04 juillet au 04 août 2021 - Marseille.</a>
        <ul className={ArtistInfos}>
         <br />
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>University Diploma - New York High School of Art and Design, New York, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- “Rooks & Robots”, Retrospective Kool Koor, Ghost Galerie Marseille, Château de Forbin, 4 Juillet - 4 Aout.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- KOOL KOOR: Sometime in the Future, Stradedarts curated by Alessandro Mantovani. Milan, Italy, June 6th - July 8th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Past Present Past, Galerie Francis Noël. Liège, Belgium, until June 25th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Tribal Movements, Galerie Martine Ehmer. Brussels, Belgium, October 1st - 4th.</p>
          <p>- Kool Koor: Black Gold, NK Gallery Antwerp, Antwerp, Belgium, December 5 th - January 22th.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Treasure Lost and Found, l’Hotêl Marignan. Paris, France, opening December 2nd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- KOOL KOOR: Transform... Evolve, Covart Gallery, under the High Patronage of the U.S. Embassy in Luxembourg. Luxembourg, May 4th - 31st.</p>
          <p>- KOOL KOOR: Papers, Begramoff Contemporary Art. Brussels, Belgium, March 4th - April 13th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Kool Koor: The Arbitrator, Covart Gallery. Luxembourg, Luxembourg, April 30th - June 25th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Schilderijen en Objecten (Paintings and Objects), Galerie DZD Art. Roermond, Netherlands, January 10th - February 8th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Koor - Back to the Roots, Gallery 151. New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Seven Sources, Tempus Arte. Landen, Belgium.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Koor, Galerie Willy Schoots. Eindhoven, Netherlands.</p>
          <p>- Microkoorsm, Fine Arts Studio. Brussels, Belgium. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Koor: Recent Works, Fine Art Studio. Brussels, Belgium.</p>
          <p>- Reality Revisited, Sabine Watchers Gallery. Knokke, Belgium.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Koor, Galerie Willy Schoots. Eindhoven, Netherlands.</p>
          <p>- Elevations, Private Domain. Deurne, Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Draftworks, Private Domain. Deurne, Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- Monoliths vs Pyroliths, Tour et Taxis. Brussels, Belgium.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- KOOR, Gallerie Dis. Maastricht, Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- KOOR, Gallerie der Stadt Esslingen am Neckar, Esslingen, Germany.</p>
          <p>- KOOR, Gallery M. Bayreuth, Germany.</p>
          <p>- KOOR, Maurice Keitelman Gallery. Brussels, Belgium.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- KOOR, Art Boom. Deurne, Holland 1988</p>
          <p>- KOOR, Gallery 69, New York, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- New York Street Style : création des années 80 - Ghost Galerie, Paris, juillet - septembre 2022.</p>
          <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Writing the futura: Basquiat and the Hip-Hop generation, Museum of fine arts, Boston, Massachusetts, USA.</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021.</p>
          <p>- Rooks & Robots, Château de Forbin, Marseille, France. 4 Juillet - 4 Octobre, 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- La Rétrospective, Manufacture 111 Bruxelles. Brussels, Belgium, August 12th - September 15th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Beyond the Wall, Art Bunker Gallery. Tourcoing, France, June 1st - 30th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Emulsions Urbaines, Galerie Martine Ehmer. Brussels, Belgium, April 11th - May 17th.</p>
          <p>- Black Gold, NK Gallery. Antwerp, Belgium, December 5th, 2014 - January 22nd, 2015.</p>
          <p>- Dalí Fait le Mur, Espace Dalí Paris. Paris, France, September 11th, 2014 - March 15th, 2015</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Art in the Streets, MOCA. Los Angeles, USA, April 17th - August 8th. (catalogue) 2010</p>
          <p>- Né dans la Rue - Graffiti, Fondation Cartier pour l’Art Contemporain. Paris, France, July 7th, 2007 - January 10th, 2010. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- East African Art Biennial. Dar es Salaam, Tanzania.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- EYE CANDY, Museum Tongerlohuys (2 man show). Roosendaal, Netherlands 2004</p>
          <p>- New York Graffiti: A Retrospective, Galerie Maurice Keitelman, Brussels, Belgium. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- The Writing on the Wall</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p id="end">- 21st ed. International Art and Antiquities Fair. Knokke Scharpoord, Belgium.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default DondiWhite;